<template>
  <div class="page">
    <Navbar title="工作经历" :callback="back" type="SMP" />
    <div class="work" v-for="work in workList" :key="work.id">
      <van-row>
        <van-col span="20" class="name">{{ work.unitName }}</van-col>
        <van-col span="4"
          ><van-tag :color="COLOR" plain @click="updateWork(work.seq)"
            >修改</van-tag
          >
        </van-col>
      </van-row>
      <van-row>
        <van-col span="20" class="time"
          ><van-icon name="todo-list-o" /> {{ work.startDate }}到{{
            work.endDate
          }}</van-col
        >
        <van-col span="4"
          ><van-tag type="danger" plain @click="deleteWork(work.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="no-record" v-if="workList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createWork()"
          >新增工作</van-button
        >
      </van-col>
    </van-row>
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      personCode: '',
      workList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveWorkList()
  },
  methods: {
    async retrieveWorkList () {
      var pd = { personCode: this.personCode, group: 'WRK', type: 'DTL' }
      this.skillList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var work = { seq: element.seq, unitName: content.unitName, unitDesc: content.unitDesc, startDate: content.startDate, endDate: content.endDate }
          this.workList.push(work)
        })
      }
    },
    createWork () {
      this.$router.push({ path: '/mde/people/person/dtlWorkEdit', query: { personCode: this.personCode, seq: this.workList.length + 1 } })
    },
    updateWork (seq) {
      this.$router.push({ path: '/mde/people/person/dtlWorkEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteWork (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除工作经历吗?'
      })
        .then(() => {
          this.deleteWorkExecute(seq)
        })
    },
    async deleteWorkExecute (seq) {
      var pd = { key: 'PSN_WRK_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveWorkList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.work {
  padding: 10px 20px;
  height: 40px;
  border-bottom: 1px solid #eee;
  text-align: left;
  .name {
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .time {
    height: 20px;
    line-height: 20px;
  }
}
.btn {
  margin-top: 10px;
}
</style>
